import React, { useEffect } from "react";
import "./footer.css";
import insta from "../../assets/img/instagram.3c71d0ef.svg";
import fb from "../../assets/img/facebook.96b5f919.svg";
import ln from "../../assets/img/linkedin.7abb6faf.svg";
import youtube from "../../assets/img/youtube.bee15f9b.svg";
import wp from "../../assets/img/whatsapp.1f721d0d.svg";
import { Link, useNavigate, useLocation } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Проверяем, нужно ли скроллить к секции после перехода на страницу
    const scrollToSection = localStorage.getItem("scrollToSection");
    if (scrollToSection) {
      setTimeout(() => {
        const section = document.getElementById(scrollToSection);
        if (section) {
          section.scrollIntoView({ behavior: "smooth" });
        }
        localStorage.removeItem("scrollToSection");
      }, 100); // Добавляем небольшую задержку
    }
  }, [location.pathname]);

  const handleScrollToSection = (page, sectionId) => {
    if (location.pathname !== page) {
      // Сохраняем в localStorage ID секции, к которой нужно прокрутиться, и переходим на страницу
      localStorage.setItem("scrollToSection", sectionId);
      navigate(page);
    } else {
      // Если уже на нужной странице, просто скроллим к секции
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return (
    <>
      <footer>
        <div className="container">
          <div className="footer">
            <div className="footer-info">
              <div className="foot-logo as">
                <a>
                  econelli<span>.</span>
                </a>
                <div className="btn"></div>
              </div>

              <div className="foot-right">
                <div className="haqqimizdaa">
                  <Link
                    to="/haqqimizda"
                    onClick={() =>
                      handleScrollToSection("/haqqimizda", "haqqimizda")
                    }
                  >
                    Haqqımızda
                  </Link>
                </div>
                <div className="meqsede">
                  <Link
                    to="/haqqimizda"
                    onClick={() =>
                      handleScrollToSection("/haqqimizda", "meqsedimiz")
                    }
                  >
                    Məqsədimiz
                  </Link>
                </div>

                <div className="fill">
                  <Link
                    to="/fil"
                    onClick={() => handleScrollToSection("/fil", "fil")}
                  >
                    FİL
                  </Link>
                </div>

                <div className="xidmet">
                  <ul>
                    <li>Təkliflərimiz</li>
                    <li>
                      <Link
                        to="/general-english"
                        onClick={() =>
                          handleScrollToSection(
                            "/general-english",
                            "general-english"
                          )
                        }
                      >
                        General English
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/ielts"
                        onClick={() => handleScrollToSection("/ielts", "ielts")}
                      >
                        IELTS
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/sat"
                        onClick={() => handleScrollToSection("/sat", "sat")}
                      >
                        SAT
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/xaricde-tehsil"
                        onClick={() =>
                          handleScrollToSection(
                            "/xaricdə-təhsil",
                            "xaricdə-təhsil"
                          )
                        }
                      >
                        Xaricdə Təhsil
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/abituriyentler-ingilis-dili"
                        onClick={() =>
                          handleScrollToSection(
                            "/abituriyentler-ingilis-dili",
                            "abieng"
                          )
                        }
                      >
                        Abituriyentlər üçün İngilis dili
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/korporativ-ingilis-dili"
                        onClick={() =>
                          handleScrollToSection(
                            "/kooperativ-ingilis-dili",
                            "koopeng"
                          )
                        }
                      >
                        Korporativ İngilis dili
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/danishiq-klublar"
                        onClick={() =>
                          handleScrollToSection("/danishiq-klublar", "d-klub")
                        }
                      >
                        Danışıq klubları
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/etudler"
                        onClick={() =>
                          handleScrollToSection("/etudler", "etud")
                        }
                      >
                        Etüdlər
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="contactuss">
              <div className="private">
                <p>© 2024 All rights reserved</p>
              </div>
              <div className="caaaaa">
                <p>
                  Bizimlə əlaqə:{" "}
                  <a href="tel:+994516190437">+994 51 619 04 37</a>
                </p>
                <div className="social-con">
                  <a
                    href="https://www.instagram.com/econelli.education/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={insta} alt="Instagram" />
                  </a>
                  <a
                    href="https://www.facebook.com/econelli/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={fb} alt="Facebook" />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/econelli/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={ln} alt="LinkedIn" />
                  </a>
                  <a
                    href="https://www.youtube.com/@Econelli8948"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={youtube} alt="YouTube" />
                  </a>
                  <a
                    href="https://api.whatsapp.com/send/?phone=994516190437&text&type=phone_number&app_absent=0"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={wp} alt="WhatsApp" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
