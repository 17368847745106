import React, { Suspense, lazy } from 'react';
import './App.css';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const Anasehife = lazy(() => import('./components/main/Anasehife/Main'));
const Fil = lazy(() => import('./components/main/FIL/Fil'));
const Haqqimizda = lazy(() => import('./components/main/Haqqimizda/Haqqimizda'));
const Abieng = lazy(() => import('./components/main/Anasehife/kurslar/Abieng'));
const Danishiqklub = lazy(() => import('./components/main/Anasehife/kurslar/Danishiqklub'));
const Etud = lazy(() => import('./components/main/Anasehife/kurslar/Etud'));
const General = lazy(() => import('./components/main/Anasehife/kurslar/General'));
const Ielts = lazy(() => import('./components/main/Anasehife/kurslar/Ielts'));
const Koopeng = lazy(() => import('./components/main/Anasehife/kurslar/Koopeng'));
const Sat = lazy(() => import('./components/main/Anasehife/kurslar/Sat'));
const Xartehsil = lazy(() => import('./components/main/Anasehife/kurslar/Xartehsil'));
const Elaqe = lazy(() => import('./components/main/elaqe/Elaqe'));
const Notf = lazy(() => import('./components/Notf'));

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          {/* Маршруты с хедером и футером */}
          <Route
            path="/"
            element={
              <>
                <Header />
                <Anasehife />
                <Elaqe/>
                <Footer />
              </>
            }
          />
          <Route
            path="/haqqimizda"
            element={
              <>
                <Header />
                <Haqqimizda />
                <Elaqe/>

                <Footer />
              </>
            }
          />
          <Route
            path="/fil"
            element={
              <>
                <Header />
                <Fil />
                <Elaqe/>

                <Footer />
              </>
            }
          />
          <Route
            path="/abituriyentler-ingilis-dili"
            element={
              <>
                <Header />
                <Abieng />
                <Elaqe/>

                <Footer />
              </>
            }
          />
          <Route
            path="/danishiq-klublar"
            element={
              <>
                <Header />
                <Danishiqklub />
                <Elaqe/>

                <Footer />
              </>
            }
          />
          <Route
            path="/etudler"
            element={
              <>
                <Header />
                <Etud />
                <Elaqe/>

                <Footer />
              </>
            }
          />
          <Route
            path="/general-english"
            element={
              <>
                <Header />
                <General />
                <Elaqe/>

                <Footer />
              </>
            }
          />
          <Route
            path="/ielts"
            element={
              <>
                <Header />
                <Ielts />
                <Elaqe/>

                <Footer />
              </>
            }
          />
          <Route
            path="/korporativ-ingilis-dili"
            element={
              <>
                <Header />
                <Koopeng />
                <Elaqe/>

                <Footer />
              </>
            }
          />
          <Route
            path="/sat"
            element={
              <>
                <Header />
                <Sat />
                <Elaqe/>

                <Footer />
              </>
            }
          />
          <Route
            path="/xaricde-tehsil"
            element={
              <>
                <Header />
                <Xartehsil />
                <Elaqe/>

                <Footer />
              </>
            }
          />

          {/* Маршрут "404" без хедера и футера */}
          <Route path="*" element={<Notf />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
